import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    BehaviorSubject, catchError, Observable
} from "rxjs";

import { environment } from "../../../environments/environment";
import { DisableInternalUserModel } from "../../shared/models/user/disable-internal-user.model";
import { Users } from "../../shared/models/users.model";
import { Vendor } from "../../shared/models/vendor.model";
import { handleError } from "../helper/http-helpers";
import { AddUserRequestDTO } from "../models/addUserRequestDTO";
import { GenericResponseDto } from "../models/genericResponseDto.model";
import { SignDocsDto } from "../models/signDocsDto.model";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private httpClient: HttpClient) {}

    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    private apiBaseUrl = environment.apiBaseUrl;

    private userDetails = new BehaviorSubject("");
    currentUserDetails = this.userDetails.asObservable();

    changeUserDetails(userDetails: string) {
        this.userDetails.next(userDetails);
    }

    getUserDetails(accountNumber: string) {
        return this.httpClient
            .get(
                `${this.apiBaseUrl}/User/details?accountNumber=${accountNumber}`,
                this.httpOptions
            )
            .pipe(catchError(handleError));
    }

    getAssociations() {
        return this.httpClient
            .get(
                `${this.apiBaseUrl}/User/getassociation`,
                this.httpOptions
            )
            .pipe(catchError(handleError));
    }

    getAllUsers(): Observable<GenericResponseDto<Users[]>> {
        return this.httpClient
            .get<
        GenericResponseDto<Users[]>
        >(`${this.apiBaseUrl}/User/GetAllUsers`, this.httpOptions)
            .pipe(catchError(handleError));
    }

    disableInternalUsers(payload: DisableInternalUserModel): Observable<GenericResponseDto<boolean>> {
        return this.httpClient
            .post<
        GenericResponseDto<boolean>
        >(`${this.apiBaseUrl}/User/DisableInternalUsers`, payload, this.httpOptions)
            .pipe(catchError(handleError));
    }

    addInternalUser(
        payload: AddUserRequestDTO[]
    ): Observable<GenericResponseDto<Users[]>> {
        return this.httpClient
            .post<
        GenericResponseDto<Users[]>
        >(`${this.apiBaseUrl}/User/AddInternalUser`, payload)
            .pipe(catchError(handleError));
    }

    getADUsers(): Observable<any> {
        return this.httpClient
            .get<
        GenericResponseDto<any[]>
        >(`${this.apiBaseUrl}/ADUser/GetADUsers`, this.httpOptions)
            .pipe(catchError(handleError));
    }

    GetADUsersByNextLink(odataNextLink: string): Observable<any> {
        return this.httpClient
            .get<
        GenericResponseDto<any[]>
        >(`${this.apiBaseUrl}/ADUser/GetADUsersByNextLink?odataNextLink=${odataNextLink}`, this.httpOptions)
            .pipe(catchError(handleError));
    }

    getVendorUsers(): Observable<GenericResponseDto<Vendor[]>> {
        return this.httpClient
            .get<
        GenericResponseDto<Vendor[]>
        >(`${this.apiBaseUrl}/User/GetVendorUsers`, this.httpOptions)
            .pipe(catchError(handleError));
    }

    callVendorSignature(
        payload: SignDocsDto
    ): Observable<GenericResponseDto<string>> {
        return this.httpClient
            .post<
        GenericResponseDto<string>
        >(`${this.apiBaseUrl}/DocuSign/Upload`, payload)
            .pipe(catchError(handleError));
    }

    callEnvelope(
        envelopeId: string
    ): Observable<GenericResponseDto<string>> {
        return this.httpClient
            .post<
        GenericResponseDto<string>
        >(`${this.apiBaseUrl}/DocuSign/Envelope`, { envelopeId })
            .pipe(catchError(handleError));
    }
}
