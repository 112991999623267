import {
    Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-envelope-frame",
    templateUrl: "./envelope-frame.component.html",
    styleUrl: "./envelope-frame.component.scss"
})
export class EnvelopeFrameComponent implements OnChanges {
    @Input() frameURL: string = "";
    @Input() showEnvelopeDialog: boolean = false;
    @Output() envelopeModalClose = new EventEmitter<void>();
    iframeUrl: SafeResourceUrl | null = null; // Properly initialized
    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["frameURL"] && changes["frameURL"].currentValue) {
            this.sanitizeIframeUrl(changes["frameURL"].currentValue);
        }
    }

    private sanitizeIframeUrl(url: string): void {
        console.log(url, "url");
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    closeFrame() {
        this.envelopeModalClose.emit();
    }
}
