<p-dialog header="Signee Email" [(visible)]="showSignDialog" (onHide)="closeModal()" [style]="{width: '40vw', height: '20vw'}"
          class="activity-popup" [modal]="true">
  <div class="custom-container">
    <div class="row mb-3 mt-5">
      <div class="col-lg-12 pb-2 d-flex justify-content-between">
        <div class="col-auto d-flex gap-4">
        </div>
      </div>
      <div [formGroup]="docSignForm" class="col-lg-12 m-auto">
        <p-card>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <span class="p-float-label">
                  <input pInputText class="w-100"
                         [formControlName]="docFormEnum.Email" type="text"
                         [ngClass]="{'error-state-input': docSignForm.get(docFormEnum.Email)?.touched && docSignForm.get(docFormEnum.Email)?.invalid}" />
                  <label for="username">Email Address</label>
                </span>
              </div>
            </div>
          </div>
        </p-card>
      </div>
      <p-button class="mt-2" label="Send Email" severity="secondary" (click)="sendSigneeEmail()">
      </p-button>
    </div>
  </div>
  <div *ngIf="inProgress" class="col-lg-12 position-fixed top-0 start-0 d-flex justify-content-center align-items-center color-box spinner-contr">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4"></p-progressSpinner>
  </div>
</p-dialog>
