import {
    Component, EventEmitter, Input, OnInit, Output
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";

import { SignDocsDto } from "../../../core/models/signDocsDto.model";
import { UserService } from "../../../core/services/user.service";
import { DocSignForm } from "./models/doc-sign-form.model";
import { DocFormEnum } from "./models/Enums/docFormEnum.enum";

@Component({
    selector: "app-doc-signer",
    templateUrl: "./doc-signer.component.html",
    styleUrl: "./doc-signer.component.scss"
})
export class DocSignerComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private messageService: MessageService
    ) { }

    @Input() accountNo: string = "";
    @Input() showSignDialog: boolean = false;
    @Output() eventModalClose = new EventEmitter<void>();
    @Output() frameURLAdd = new EventEmitter<string>();
    docFormEnum = DocFormEnum;
    inProgress = false;

    docSignForm = this.fb.group<DocSignForm>({
        [DocFormEnum.AccountNo]: this.fb.control<string | null>("", { nonNullable: true }),
        [DocFormEnum.Email]: this.fb.control<string | null>("", {
            validators: [
                Validators.required,
                Validators.pattern("^[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}$")
            ]
        }),
    });

    ngOnInit(): void {
        this.docSignForm.patchValue({
            [DocFormEnum.AccountNo]: this.accountNo
        });
    }

    closeModal() {
        this.eventModalClose.emit();
    }

    sendSigneeEmail() {
        if (this.docSignForm.valid) {
            this.inProgress = true;
            const payload: SignDocsDto = {
                email: this.docSignForm.value[DocFormEnum.Email],
                vendorAccountNo: this.docSignForm.value[DocFormEnum.AccountNo],
            };
            this.userService.callVendorSignature(payload).subscribe({
                next: (res) => {
                    this.inProgress = false;
                    if (res.status.toLowerCase() === "success") {
                        this.docSignForm.reset();
                        this.closeModal();
                        this.frameURLAdd.emit(res.data || "");
                    } else {
                        this.messageService.add({
                            severity: "error",
                            summary: "Error",
                            detail:
                res.message
                || `Error while sending link`,
                        });
                    }
                },
                error: () => {
                    this.inProgress = false;
                    this.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: `Something went wrong while sending link`,
                    });
                },
            });
        }
    }
}
