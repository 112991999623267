import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { BadgeModule } from "primeng/badge";
import { ButtonModule } from "primeng/button";
import { CardModule } from "primeng/card";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { EditorModule } from "primeng/editor";
import { FileUploadModule } from "primeng/fileupload";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";

import { PhoneMaskDirective } from "../../core/helper/phone-mask-directive";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { TabComponent } from "../../shared/components/tab/tab.component";
import { BadgeNotificationsComponent } from "../vendor/components/badge-notifications/badge-notifications.component";
import { VendorNotificationComponent } from "../vendor/components/vendor-notification/vendor-notification.component";
import { VendorSummeryComponent } from "../vendor/components/vendor-summery/vendor-summery.component";
import { DocSignerComponent } from "./doc-signer/doc-signer.component";
import { EmailModalComponent } from "./email-modal/email-modal.component";
import { EnvelopeFrameComponent } from "./envelope-frame/envelope-frame.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";

@NgModule({
    declarations: [HeaderComponent, TabComponent, PdfViewerComponent, VendorSummeryComponent,
        VendorNotificationComponent, BadgeNotificationsComponent, EmailModalComponent,
        PhoneMaskDirective, PageNotFoundComponent, DocSignerComponent, EnvelopeFrameComponent],
    imports: [
        CommonModule,
        InputSwitchModule,
        ToggleButtonModule,
        FormsModule,
        ButtonModule,
        RouterModule,
        NgxExtendedPdfViewerModule,
        SplitButtonModule,
        FileUploadModule,
        DialogModule,
        BadgeModule,
        CardModule,
        DividerModule,
        EditorModule,
        ProgressSpinnerModule,
        ConfirmDialogModule,
        ReactiveFormsModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        ToastModule,
        OverlayPanelModule
    ],
    exports: [HeaderComponent, EmailModalComponent, TabComponent, PdfViewerComponent, VendorSummeryComponent,
        VendorNotificationComponent, BadgeNotificationsComponent, EditorModule,
        InputTextModule, InputTextareaModule, DropdownModule, PhoneMaskDirective, DocSignerComponent,
        EnvelopeFrameComponent],
})
export class SharedModule {}
